<template>
    <el-dialog width="600px" top="40px" title="Kommentarer" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <div class="px-16 py-8">
            <el-form ref="form" :model="formData" :rules="rules">
                <el-form-item prop="newComment">
                    <el-input v-model="formData.newComment" type="textarea" placeholder="Lägg till ny kommentar" resize="none" rows="5" />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
            <el-button @click="addComment" type="primary" :loading="$waiting.is('modal.adding')">Lägg till</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../invoices.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        sourceId: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            formData: {},
            rules: {
                newComment: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    methods: {
        async addComment() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("modal.adding");
            try {
                await Api.addComment(this.sourceId, this.formData.newComment);
                this.$notify.success({ title: "Utfört" });
                this.$emit("refresh");
                this.resetModalState();
                this.closeModal();
            } finally {
                this.$waiting.end("modal.adding");
            }
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.$emit("close");
        },

        resetModalState() {
            this.formData.newComment = "";
        },
    },
};
</script>
